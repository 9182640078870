<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <!--<li>
            <a href=""> Blog </a>
          </li>
          <li>
              <a href=""> Licenses </a>
          </li>-->
        </ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href=""
          >{{ WhiteLabellingTitle() }}</a
        >
      </div>
    </div>
  </footer>
  
</template>
<script>
export default {
  data() {
    return {
      host: window.location.host,
    };
  },
};
</script>
<style></style>
